export const heading = ["Plan", "Plans used", "Roaming totals", "Overage totals", "Other costs", "Contractual vs actual cost", ""];

export const tableData = [
  {
    id: 0,
    name: "Data Plan 1",
    plan_used: "70/100",
    roaming_totals: "2675",
    overage_totals: "3212",
    other_costs: "2675",
    contractual_cost: "215.35",
    actual_cost: "300.34"
  },
  {
    id: 1,
    name: "Data Plan 2",
    plan_used: "78/100",
    roaming_totals: "3421",
    overage_totals: "2675",
    other_costs: "1342",
    contractual_cost: "230.24",
    actual_cost: "256.67"
  },
  {
    id: 2,
    name: "Data Plan 3",
    plan_used: "50/100",
    roaming_totals: "1123",
    overage_totals: "879",
    other_costs: "5621",
    contractual_cost: "321.56",
    actual_cost: "643.12"
  },
];
