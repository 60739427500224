export const heading = ["License type", "Color prints", "Black and white prints", "License fee", "Contractual vs actual cost"];

export const tableData = [
  {
    id: 1,
    license_type: "Basic",
    color_prints: 70,
    black_and_white_prints: 40,
    license_fee: 300,
    contractual_vs_actual_cost: "500/1000",
  },
  {
    id: 2,
    license_type: "Standard",
    color_prints: 50,
    black_and_white_prints: 30,
    license_fee: 350,
    contractual_vs_actual_cost: "600/1500",
  },
  {
    id: 3,
    license_type: "Pro",
    color_prints: 60,
    black_and_white_prints: 45,
    license_fee: 450,
    contractual_vs_actual_cost: "700/2000",
  },
];

