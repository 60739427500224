import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/Buttons/button";
import Search from "../../../../../assets/images/search.png";
import menuDot from "../../../../../assets/images/menuDot.png";
import BasicModal from "../../../../models/models";
import {
  getAuthorizedServices,
  getInitializeServicesData,
  setServiceCreatedFalse,
  getManageServicesDataScore,
  selectCompanyDashboard,
} from "../../../../../redux/slices/companyDashboardSlice";
import {
  getUserDetails,
  selectUser,
} from "../../../../../redux/slices/userSlice";
import DataScoreCard from "../../../../../components/data-score-card/DataScoreCard";
import Pill from "../../../../Pill/pill";
import Unicon from "../../../../../assets/images/arrow-down-circle.svg";
import cn from "classnames";
import { useLocation } from "react-router-dom";

const ManageServices = ({ setModel }) => {
  const dispatch = useDispatch();
  const [popUp, setPopUp] = useState(false);
  const [id, setId] = useState("");
  const [state, setState] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [menuDotPopup, setMenuDotPopup] = useState(false);
  const [newServiceAdded, setNewServiceAdded] = useState(false);
  const [sortedServices, setSortedServices] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  // const [saveSetup, setSaveSetup] = useState(false);
  const State = useSelector(selectCompanyDashboard);
  const {
    authorizedServicesData,
    serviceUpdated,
    serviceMenuUpdated,
    manageServicesData,
    UserServiceData,
  } = State;
  const userState = useSelector(selectUser);
  const { userDetails } = userState;
  const adminRole = userDetails.user.role;
  const service_ids = manageServicesData.service_id_without_USD;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const service_id = params.get("service_id");

  useEffect(() => {
    if (service_id) {
      setPopUp(true);
      setState(32);
      setId(service_id);
    }
  }, [service_id]);

  useEffect(() => {
    dispatch(getAuthorizedServices());
    dispatch(getInitializeServicesData());
  }, [dispatch, serviceUpdated, state, serviceMenuUpdated]);

  useEffect(() => {
    if (authorizedServicesData?.authorized_services) {
      setSortedServices([...authorizedServicesData.authorized_services]);
    }
  }, [authorizedServicesData]);

  useEffect(() => {
    if (!popUp) {
      setTimeout(() => {
        const buttons = document.querySelectorAll("button");
        buttons.forEach((btn) => btn.blur()); // remove focus all buttons
      }, 100);
    }
  }, [popUp]);

  const displayServices = sortedServices.filter((service) =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSetup = (serviceId) => {
    setPopUp(true);
    setState(32);
    setId(serviceId);
  };

  function handleManage(serviceId) {
    // setPopUp(true);
    // setState(35);
    // setId(serviceId);
    localStorage.setItem("manageServiceId", serviceId);
    window.open("/personal_dashboard/services");
  }

  const handleMenuDotClick = (service) => {
    setMenuDotPopup(true);
    if (service.reporting_status === false) {
      setState(31);
      setId(service.id);
    } else {
      setState(30);
      setId(service.id);
    }
  };

  useEffect(() => {
    if (newServiceAdded && newServiceAdded) {
      dispatch(getAuthorizedServices());
      dispatch(getInitializeServicesData());
      setNewServiceAdded(false);
      setState(29);
    }
  }, [newServiceAdded]);

  const handleSortByName = () => {
    const sorted = [...authorizedServicesData.authorized_services].sort(
      (a, b) => {
        if (sortOrder === "asc") {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      }
    );
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedServices(sorted);
  };

  const handleIntegrations = () => {
    // const sorted = [...authorizedServicesData.authorized_services].sort((a, b) => {
    //   if (sortOrder === "asc") {
    //     return a.name.localeCompare(b.name);
    //   } else {
    //     return b.name.localeCompare(a.name);
    //   }
    // });
    // setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    // setSortedServices(sorted);
  };

  const handleReporting = () => {
    const sorted = [...authorizedServicesData.authorized_services].sort(
      (a, b) => {
        if (sortOrder === "asc") {
          return a.reporting_status - b.reporting_status;
        } else {
          return b.reporting_status - a.reporting_status;
        }
      }
    );
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedServices(sorted);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  };

  const handleLatestReporting = () => {
    const sorted = [...authorizedServicesData.authorized_services].sort(
      (a, b) => {
        const dateA = parseDate(a.added);
        const dateB = parseDate(b.added);
        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      }
    );

    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedServices(sorted);
  };

  const handleAdded = () => {
    const sorted = [...authorizedServicesData.authorized_services].sort(
      (a, b) => {
        const dateA = parseDate(a.added);
        const dateB = parseDate(b.added);
        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      }
    );

    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedServices(sorted);
  };

  useEffect(() => {
    dispatch(getManageServicesDataScore());
  }, [dispatch, UserServiceData]);

  const handleDataScore = () => {
    setState(53);
    setPopUp(true);
  };

  return (
    <div className="px-4 pt-6 ManageServicesBox">
      <div className="headingBox flex justify-between items-center mb-6 px-4">
        <h2 className="text-2xl font-bold">Manage Services</h2>
        {adminRole == "admin" && (
          <Button
            onClick={() => {
              setPopUp(true);
              setState(28);
              dispatch(setServiceCreatedFalse());
            }}
            outlined={true}
            hoverColor={true}
            secondary={true}
            secondaryHoverOutlined={true}
          >
            + Add Service
          </Button>
        )}
      </div>

      <div
        onClick={() => handleDataScore()}
        className="pt-[10px] pb-[30px] cursor-pointer"
      >
        <DataScoreCard
          manageServicesData={manageServicesData}
          service_ids={service_ids}
        />
      </div>

      <div className="searchBox mx-4">
        <img src={Search} alt="icon" className="searchIcon" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search services"
          className="searchInput"
        />
      </div>

      <div className="rounded-t-xl !mt-0 w-[100%] scrollbar-hide scrollable_data">
        <div className="overflow-x-auto scrollbar-hide custom_scroll_hide">
          <table className="sticky top-0">
            <thead className="table-head py-2 sticky top-0 bg-[#363D50]">
              <tr>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] pl-[4px] pr-[12px] w-[351px]`}
                >
                  <Button
                    className="sorting_btn_wrapper_service"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleSortByName}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Service
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc",
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] px-[16px] w-[160px]`}
                >
                  <Button
                    className="sorting_btn_wrapper_service"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleReporting}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Status
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc",
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] px-[16px]`}
                >
                  <Button
                    className="sorting_btn_wrapper_service"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleIntegrations}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Integration
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc",
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] px-[16px]`}
                >
                  <Button
                    className="sorting_btn_wrapper_service"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleLatestReporting}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Latest Report Uploaded
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc",
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] px-[16px]`}
                >
                  &nbsp;
                </th>
                {/* <th className={`text-left text-secondary border-[#434A5D] py-[12px] px-[12px]`} >&nbsp;</th> */}
              </tr>
            </thead>

            <tbody>
              {displayServices?.map((service) => (
                <tr
                  key={service.id}
                  className="hover:bg-[#363D50] hover:brightness-110 "
                >
                  <td className="text-left text-secondary !text-[#FFFFFF] py-[13.5px] px-[16px]">
                    <div className="flex flex-col md:flex-row items-center gap-[10px]">
                      {service?.category === "Expenses" ? (
                        <div className="p-1 rounded-lg bg-black">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.6665 8.00163C4.6665 6.1611 6.15846 4.66699 7.99984 4.66699H23.9998C25.8412 4.66699 27.3332 6.1611 27.3332 8.00163V26.7531C27.3332 27.8154 26.1486 28.4563 25.2602 27.864L23.1079 26.4292C22.8664 26.2681 22.5484 26.2819 22.3217 26.4633L20.5826 27.8546C19.8521 28.4389 18.8142 28.4389 18.0838 27.8546L16.4163 26.5206C16.1728 26.3258 15.8269 26.3258 15.5834 26.5206L13.9159 27.8546C13.1855 28.4389 12.1476 28.4389 11.4171 27.8546L9.67802 26.4633C9.45132 26.2819 9.13332 26.2681 8.89176 26.4292L6.73944 27.864C5.85112 28.4563 4.6665 27.8154 4.6665 26.7531V8.00163ZM7.99984 6.00033C6.89569 6.00033 5.99984 6.89664 5.99984 8.00163V26.7531L8.15216 25.3198C8.87684 24.8366 9.83085 24.878 10.5109 25.4221L12.25 26.8134C12.4935 27.0082 12.8395 27.0082 13.083 26.8134L14.7504 25.4794C15.4809 24.8951 16.5188 24.8951 17.2492 25.4794L18.9167 26.8134C19.1602 27.0082 19.5062 27.0082 19.7496 26.8134L21.4887 25.4221C22.1688 24.878 23.1228 24.8366 23.8475 25.3198L25.9992 26.7542L25.9998 8.00163C25.9998 6.89664 25.104 6.00033 23.9998 6.00033H7.99984ZM9.99984 12.0003C9.99984 11.6321 10.2983 11.3337 10.6665 11.3337H16.6665C17.0347 11.3337 17.3332 11.6321 17.3332 12.0003C17.3332 12.3685 17.0347 12.667 16.6665 12.667H10.6665C10.2983 12.667 9.99984 12.3685 9.99984 12.0003ZM19.9998 12.0003C19.9998 11.6321 20.2983 11.3337 20.6665 11.3337H21.3332C21.7014 11.3337 21.9998 11.6321 21.9998 12.0003C21.9998 12.3685 21.7014 12.667 21.3332 12.667H20.6665C20.2983 12.667 19.9998 12.3685 19.9998 12.0003ZM9.99984 17.3337C9.99984 16.9655 10.2983 16.667 10.6665 16.667H21.3332C21.7014 16.667 21.9998 16.9655 21.9998 17.3337C21.9998 17.7018 21.7014 18.0003 21.3332 18.0003H10.6665C10.2983 18.0003 9.99984 17.7018 9.99984 17.3337Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      ) : (
                        <img
                          className="w-[40px] h-[40px] card_img rounded-lg"
                          src={service.image}
                          alt="service-img"
                        />
                      )}
                      <div className="flex flex-col">
                        <span className="text-secondary lineHeight !text-[#FFFFFF] !text-left !font-medium">
                          {service.name}
                        </span>
                        <span className="text-secondary lineHeight !text-left">
                          {service.category}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-left text-secondary !text-[#FFFFFF] py-[13.5px] px-[28px] !font-medium font_12px">
                    {/* <div>{service.reporting_status}</div> */}
                    {service.reporting_status === true ? (
                      <Pill greenFill={true}> Active </Pill>
                    ) : (
                      <Pill redFill={true}> Needs setup </Pill>
                    )}
                  </td>
                  <td className="text-left text-secondary !text-[#FFFFFF] py-[13.5px] px-[28px] font_12px">
                    {service.status === "Active" ? (
                      <Pill greenFill={true}>Active</Pill>
                    ) : (
                      <Pill redFill={true}>Inactive</Pill>
                    )}
                  </td>
                  <td className="text-left lineHeight text-secondary !text-[#FFFFFF] py-[13.5px] px-[28px] !font-medium">
                    {service.latest_report}
                  </td>
                  <td className="text-left text-secondary !text-[#FFFFFF] py-[13.5px] px-[28px] !font-medium lineHeight21 btn_td">
                    {service.reporting_status === false ? (
                      <Button
                        onClick={() => handleSetup(service.id)}
                        outlined={true}
                        hoverColor={true}
                        secondary={false}
                        secondaryHoverOutlined={true}
                        fill={true}
                      >
                        Setup
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleManage(service.id)}
                        outlined={true}
                        hoverColor={true}
                        secondary={true}
                        secondaryHoverOutlined={true}
                      >
                        Manage
                      </Button>
                    )}
                  </td>
                  {/* <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[13.5px] px-[12px] !font-medium"
                >
                  <div
                    className="menuDot cursor-pointer"
                    onClick={() => handleMenuDotClick(service)}
                  >
                    <img src={menuDot} alt="icon" />
                  </div>
                </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {popUp && (
        <BasicModal
          state={state}
          popup={popUp}
          setPopup={setPopUp}
          setState={setState}
          id={id}
          setId={setId}
          setModel={setModel}
          setNewServiceAdded={setNewServiceAdded}
          handleSetup={handleSetup}
          // manageServicesData={manageServicesData}
          // setSaveSetup={setSaveSetup}
        />
      )}

      {menuDotPopup && (
        <BasicModal
          popup={menuDotPopup}
          setPopup={setMenuDotPopup}
          state={state}
          setModel={setModel}
          id={id}
        />
      )}
    </div>
  );
};

export default ManageServices;
